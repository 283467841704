import React from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import { Button, Typography, useTheme, Grid, TextField } from "@plipag/ceci-ui";
import axios from "axios";

export function ForgotPasswordForm({ onSubmit }) {
  const theme = useTheme();
  const [success, setSuccess] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const validationSchema = yup.object({
    email: yup.string().required("Informe seu e-mail"),
  });

  const { handleSubmit, handleChange, handleBlur, errors, touched, values } =
    useFormik({
      initialValues: {
        email: "",
      },
      validationSchema,
      onSubmit: (values) => {
        const url = `${process.env.REACT_APP_API_HOST}/auth/forgot_password`;
        axios
          .post(url, values)
          .then(() => {
            setSuccess(true);
          })
          .catch((error) => {
            setSuccess(false);
            console.error(error);
          })
          .finally(() => setLoading(false));
      },
    });

  return (
    <Grid
      className="ForgotPasswordForm"
      container
      component="form"
      onSubmit={handleSubmit}
      direction="column"
      spacing={4}
    >
      <Grid item>
        <TextField
          label="E-mail"
          name="email"
          error={touched.email && Boolean(errors.email)}
          helperText={touched.email && errors.email}
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.email}
          fullWidth
          onKeyPress={(e) => (e.code === "Enter" ? handleSubmit() : null)}
        />
      </Grid>
      {success === true && (
        <Grid item>
          <Typography sx={{ color: theme.palette.success.main }}>
            Em poucos instantes você receberá um email com as instruções de
            acesso.
          </Typography>
        </Grid>
      )}
      {success === false && (
        <Grid item>
          <Typography sx={{ color: theme.palette.error.main }}>
            Algo deu errado, tente novamente.
          </Typography>
        </Grid>
      )}
      <Grid item>
        <Button
          type="submit"
          size="large"
          variant="contained"
          color="secondary"
          fullWidth
          disabled={loading}
        >
          Enviar por e-mail
        </Button>
      </Grid>
    </Grid>
  );
}
