import React from "react";
import { Box, Typography } from "@plipag/ceci-ui";
import SideImage from "../../assets/side-chart.svg";
import Logo from "../../assets/logo-side.svg";
import { CreateAccountButton } from "../CreateAccountButton";

export function Sidebar() {
  return (
    <Box
      sx={{
        minWidth: 400,
        maxWidth: 480,
        minHeight: "100%",
        background: "#26427c",
        padding: "60px 50px",
        color: "#fff",
        zIndex: 10,
      }}
    >
      <img src={Logo} style={{ marginBottom: 80 }} alt="" />
      <Typography component="h2" variant="h2" paragraph>
        Gestão de clientes <br />
        e cobranças
        <br />
        em um só lugar
      </Typography>
      <img src={SideImage} style={{ maxWidth: "130%" }} alt="" />
      <CreateAccountButton fullWidth sx={{ mt: 8 }} />
    </Box>
  );
}
