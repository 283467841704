import React from "react";
import { Route } from "react-router-dom";
import { Login } from "./screens/Login";
import { ForgotPassword } from "./screens/ForgotPassword";

const Routes = () => {
  return (
    <>
      <Route path="/" exact component={Login} />
      <Route path="/recuperar-senha" exact component={ForgotPassword} />
    </>
  );
};

export default Routes;
