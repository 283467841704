import React from "react";
import { Button } from "@plipag/ceci-ui";

export function CreateAccountButton(props) {
  return (
    <Button
      onClick={() => global.window.open(process.env.REACT_APP_SIGNUP_URL)}
      size="large"
      variant="contained"
      color="success"
      sx={{ mt: 2 }}
      {...props}
    >
      CRIAR CONTA GRÁTIS
    </Button>
  );
}
