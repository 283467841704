import React from "react";
import { Typography, Grid, useMediaQuery } from "@plipag/ceci-ui";
import { Sidebar } from "../Sidebar";
import { CreateAccountButton } from "../CreateAccountButton";

export function Layout({ children }) {
  const smallDevice = useMediaQuery("(max-width:1100px)");
  return (
    <Grid container alignItems="stretch" minHeight="100vh">
      {!smallDevice && <Sidebar />}
      <Grid
        flexGrow={1}
        alignItems="center"
        justifyContent="center"
        display="flex"
        sx={{ background: "#f5f7fa" }}
      >
        <Grid container justifyContent="center">
          {children}

          {smallDevice && (
            <Grid item sx={{ marginTop: 4, textAlign: "center" }}>
              <Typography>Ainda não tem conta?</Typography>
              <CreateAccountButton />
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
}
