import React from "react";
import { Link } from "react-router-dom";
import { Button } from "@plipag/ceci-ui";
import { Card } from "../../components/Card";

import { ForgotPasswordForm } from "../../components/ForgotPasswordForm";

export function ForgotPassword() {
  return (
    <Card
      title="Digite o e-mail da sua conta e enviaremos uma nova senha."
      actions={
        <Button
          component={Link}
          to="/"
          style={{ margin: "0 auto 20px" }}
          size="large"
        >
          Voltar para login
        </Button>
      }
    >
      <ForgotPasswordForm />
    </Card>
  );
}
