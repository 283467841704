import React from "react";
import * as yup from "yup";
import { useFormik } from "formik";
import axios from "axios";
import { Link } from "react-router-dom";
import {
  Button,
  Grid,
  PasswordField,
  TextField,
  Typography,
  useTheme,
} from "@plipag/ceci-ui";
import { Card } from "../../components/Card";

const ErrorMessage = ({ message }) => {
  const theme = useTheme();
  return (
    <Typography sx={{ color: theme.palette.error.main }}>{message}</Typography>
  );
};

export function Login() {
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState("");
  const validationSchema = yup.object({
    email: yup.string().required("Informe seu e-mail"),
    password: yup.string().required("Informe a senha"),
  });
  const { handleSubmit, handleChange, handleBlur, errors, touched, values } =
    useFormik({
      initialValues: {
        email: "",
        password: "",
      },
      validationSchema,
      onSubmit: (values) => {
        setLoading(true);
        const url = `${process.env.REACT_APP_API_HOST}/auth/request_token`;
        axios
          .post(url, { ...values, trusted: true })
          .then((response) => {
            if (response.data.data.token) {
              localStorage.setItem("token", response.data.data.token);
              window.location.href = `${process.env.REACT_APP_CONTA_PUBLIC_URL}/set_token/${response.data.data.token}`;
            } else {
              setError("Login ou senha incorretos");
            }
          })
          .catch((error) => {
            console.error(error);
            setError("Algo deu errado, tente novamente.");
          })
          .finally(() => setLoading(false));
      },
    });

  return (
    <Card
      title="Acesse sua Conta PLIPAG"
      children={
        <Grid
          container
          component="form"
          onSubmit={handleSubmit}
          direction="column"
          spacing={4}
        >
          <Grid item>
            <TextField
              label="E-mail"
              name="email"
              error={touched.email && Boolean(errors.email)}
              helperText={touched.email && errors.email}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.email}
              fullWidth
              onKeyPress={(e) => (e.key === "Enter" ? handleSubmit() : null)}
            />
          </Grid>
          <Grid item>
            <PasswordField
              fullWidth
              label="Senha"
              name="password"
              error={touched.password && Boolean(errors.password)}
              helperText={touched.password && errors.password}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.password}
              onKeyPress={(e) => (e.key === "Enter" ? handleSubmit() : null)}
            />
          </Grid>
          {error && (
            <Grid item>
              <ErrorMessage message={error} />
            </Grid>
          )}
          <Grid item>
            <Button
              type="submit"
              size="large"
              variant="contained"
              color="secondary"
              fullWidth
              disabled={loading}
            >
              Entrar
            </Button>
          </Grid>
        </Grid>
      }
      actions={
        <Button
          component={Link}
          to="/recuperar-senha"
          style={{ margin: "0 auto 20px" }}
          size="large"
        >
          Esqueci minha senha
        </Button>
      }
    />
  );
}
