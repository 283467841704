import React from "react";
import {
  Card as CeciCard,
  CardActions,
  CardContent,
  CardHeader,
} from "@plipag/ceci-ui";
import Logo from "../Logo";

export function Card({ title, children, actions, ...props }) {
  return (
    <CeciCard
      sx={{
        width: "95%",
        maxWidth: { md: 480, sm: "none" },
        textAlign: "center",
      }}
      {...props}
    >
      <CardHeader title={<Logo />} subheader={title} />
      <CardContent
        sx={{ paddingLeft: { md: 5, sm: 2 }, paddingRight: { md: 5, sm: 2 } }}
      >
        {children}
      </CardContent>
      <CardActions sx={{ paddingTop: 0 }}>{actions}</CardActions>
    </CeciCard>
  );
}
